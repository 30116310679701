import apiClient from './basicapi'

function getNotificationRecipients() {
  return apiClient
    .get(`contactservice/admin/contact-recipients`)
    .then((res) => res.data)
}

function postNotificationRecipients(recipient) {
  return apiClient.post(`contactservice/admin/contact-recipients`, recipient)
}

function putNotificationRecipients(id, recipient) {
  return apiClient.put(
    `contactservice/admin/contact-recipients/${id}`,
    recipient
  )
}

function deleteNotificationRecipients(id) {
  return apiClient.delete(`contactservice/admin/contact-recipients/${id}`)
}

export {
  getNotificationRecipients,
  postNotificationRecipients,
  putNotificationRecipients,
  deleteNotificationRecipients,
}
