import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { makeStyles } from '@mui/styles'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material'
import {
  MailRounded,
  AccessibilityNewRounded,
  ForumRounded,
} from '@mui/icons-material'

const drawerWidth = 285

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: '64px',
    height: 'calc(100vh - 64px)',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  link: {
    transition: '.2s ease-in-out',
  },
  linkcurrent: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
}))

function Navigation({ location }) {
  const classes = useStyles()

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          <List>
            <Typography variant="h4" className={classes.title}>
              Kontaktanfragen
            </Typography>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes('/'),
              })}
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <ForumRounded />
              </ListItemIcon>
              <ListItemText primary="Kontaktformular" />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]: location?.pathname?.includes(
                  'accessability-contact-requests'
                ),
              })}
              component={Link}
              to="/accessability-contact-requests/"
            >
              <ListItemIcon>
                <AccessibilityNewRounded />
              </ListItemIcon>
              <ListItemText primary="Barriere-melden Formular" />
            </ListItem>

            <ListItem
              button
              className={classNames(classes.link, {
                [classes.linkcurrent]:
                  location?.pathname?.includes('contact-recipients'),
              })}
              component={Link}
              to="/contact-recipients/"
            >
              <ListItemIcon>
                <MailRounded />
              </ListItemIcon>
              <ListItemText primary="E-Mail Einstellungen" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  )
}

Navigation.propTypes = {
  location: PropTypes.object,
}

export default Navigation
