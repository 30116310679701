import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { getContactRequests } from '@services/contactrequestservice'
import { Box } from '@mui/material'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'
import ContactRequestCard from '@objects/cards/contactrequestcard'

export const frontmatter = {
  title: 'Kontaktformular',
}

function ContactRequestsPage() {
  const intl = useIntl()
  const [cards, setCards] = useState([])

  useEffect(() => {
    getContactRequests().then((data) => {
      setCards(data)
    })
  }, [])

  function onActionClick() {
    getContactRequests().then((data) => {
      setCards(data)
    })
  }

  function renderCards() {
    if (!cards?.length || !!!cards.map) {
      return null
    }
    return (
      <Box>
        {cards?.map((card) => (
          <ContactRequestCard
            onActionClick={onActionClick}
            key={card.id}
            id={card.id}
            name={card.name}
            email={card.email}
            description={card.copy}
            privacyPolicy={card.privacyPolicy}
            timestamp={card.timestamp}
          />
        ))}
      </Box>
    )
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'contactrequestpage.introtext',
        })}
      />
      <LoadingSkeleton loading={!!!cards?.length} type="table">
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}

ContactRequestsPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default ContactRequestsPage
