import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'

function TableHead({ heads }) {
  const [sortActiveHeadId, setSortActiveHeadId] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')

  function sortHandler(id) {
    if (sortActiveHeadId !== id) {
      setSortActiveHeadId(id)
      setSortOrder('desc')
    } else {
      setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
    }
  }

  function renderHeads() {
    return heads.map((head) => {
      return (
        <TableCell key={head.id} align="left" sortDirection="desc">
          <TableSortLabel
            active={sortActiveHeadId === head.id}
            direction={sortActiveHeadId === head.id ? sortOrder : 'asc'}
            onClick={() => sortHandler(head.id)}
          >
            {head.label}
          </TableSortLabel>
        </TableCell>
      )
    })
  }

  return (
    <MuiTableHead>
      <TableRow>{renderHeads()}</TableRow>
    </MuiTableHead>
  )
}

TableHead.propTypes = {
  className: PropTypes.string,
  heads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default TableHead
