import React from 'react'
import PropTypes from 'prop-types'
import {
  TableCell,
  TableRow as MuiTableRow,
  Tooltip,
  IconButton,
} from '@mui/material'

function TableRow({ id, items, hideActions, actions }) {
  function renderRows() {
    return items.map((item, i) => (
      <TableCell key={item + i} align="left">
        {item.label}
      </TableCell>
    ))
  }

  return (
    <MuiTableRow>
      {renderRows()}
      {!!actions && (
        <TableCell>
          {!hideActions &&
            actions?.map((action, i) => {
              if (action.type === 'createcomponent') {
                return action.component({ id, items })
              } else {
                return (
                  <Tooltip key={`actions${i}`} arrow title={action.title}>
                    <IconButton
                      onClick={() => action.onClick({ id, items })}
                      size="large"
                    >
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                )
              }
            })}
        </TableCell>
      )}
    </MuiTableRow>
  )
}

TableRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hideActions: PropTypes.bool,
  items: PropTypes.array,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
}

export default TableRow
