import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { getAccesabilityContactRequests } from '@services/contactrequestservice'
import { Box } from '@mui/material'

import LoadingSkeleton from '@objects/loadingSkeleton'
import WarningNote from '@objects/warningnote'
import ContactRequestCard from '@objects/cards/contactrequestcard'

export const frontmatter = {
  title: 'Barriere-melden Formular',
}

function AccessabilityContactRequestsPage() {
  const intl = useIntl()
  const [accessabilityCards, setAccessabilityCards] = useState([])

  useEffect(() => {
    getAccesabilityContactRequests().then((data) => {
      setAccessabilityCards(data)
    })
  }, [])

  function onActionClick() {
    getAccesabilityContactRequests().then((data) => {
      setAccessabilityCards(data)
    })
  }

  function renderCards() {
    if (!accessabilityCards?.length || !!!accessabilityCards.map) {
      return null
    }
    return (
      <Box>
        {accessabilityCards?.map((card) => (
          <ContactRequestCard
            onActionClick={onActionClick}
            timestamp={card.timestamp}
            key={card.id}
            id={card.id}
            url={card.url}
            description={card.description}
            email={card.email}
            hasFile={card.hasFile}
            isAccessabilityCards
          />
        ))}
      </Box>
    )
  }
  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'contactrequestpage.introtext',
        })}
      />
      <LoadingSkeleton loading={!!!accessabilityCards.length} type="table">
        {renderCards()}
      </LoadingSkeleton>
    </>
  )
}

AccessabilityContactRequestsPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default AccessabilityContactRequestsPage
