import React, { useState, createContext } from 'react'
import { Dialog } from '@mui/material'
import AreYouSureDialog from './types/areyousure'
import EditRecipientDialog from './types/editrecipient'
import AddRecipientDialog from './types/addrecipient'

export const dialogTypes = ['areyousure', 'addrecipient', 'editrecipient']

export const DialogContext = createContext()

export function DialogProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('areyousure')
  const [onOk, setOnOk] = useState(null)
  const [customProps, setCustomProps] = useState({})

  function toggle(dialogtype, onOkCallback, customProps) {
    if (type !== dialogtype) setType(dialogtype)
    if (onOkCallback && !open) setOnOk(() => onOkCallback) // set onOk only if dialog is not open yet
    if (customProps && !open) setCustomProps(customProps)
    if (open) {
      setOnOk(null) // set onOk to null if dialog is about to close
      setCustomProps({})
    }
    setOpen(!open)
  }

  function renderDialog() {
    if (!open) return null
    const dialogprops = {
      setOpen: setOpen,
      onOk: onOk,
    }
    switch (type) {
      case 'addrecipient': {
        const { variant } = customProps
        if (!variant) {
          console.error(
            'Error DIALOG: Could not open "editrecipient" dialog. Data is missing in customProps of dialog.'
          )
          return <div>...</div>
        }
        return <AddRecipientDialog variant={variant} {...dialogprops} />
      }
      case 'editrecipient': {
        const { recipientid, name, email, variant } = customProps
        if (!recipientid || !name || !email || !variant) {
          console.error(
            'Error DIALOG: Could not open "editrecipient" dialog. Data is missing in customProps of dialog.'
          )
          return <div>...</div>
        }
        return (
          <EditRecipientDialog
            {...dialogprops}
            variant={variant}
            recipientid={recipientid}
            name={name}
            email={email}
          />
        )
      }
      case 'areyousure':
        return <AreYouSureDialog {...dialogprops} />
      default:
        return <div>...</div>
    }
  }

  return (
    <DialogContext.Provider value={{ toggle }}>
      {children}
      <Dialog open={open} onClose={() => setOpen(false)}>
        {renderDialog()}
      </Dialog>
    </DialogContext.Provider>
  )
}

export default DialogContext
