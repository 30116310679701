import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material'

import TableHead from './tableHead'
import TableRow from './tableRow'

function Table({ heads, rows, actions }) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function renderRows() {
    return rows
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        return (
          <TableRow
            key={row.id}
            id={row.id}
            items={row.items}
            hideActions={row.hideActions}
            actions={actions}
          />
        )
      })
  }

  return (
    <TableContainer component={Paper}>
      <MuiTable sx={{ minWidth: 650 }}>
        <TableHead heads={heads} />
        <TableBody>{renderRows()}</TableBody>
      </MuiTable>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100, 250]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

Table.propTypes = {
  heads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      items: PropTypes.array.isRequired,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.any,
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
}

export default Table
