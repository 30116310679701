import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { AuthProvider } from '@providers/AuthProvider'
import { FormattedMessage } from 'react-intl'
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material'
import LogoutButton from '@objects/logoutbutton'
import Navigation from '@components/navigation'

function Layout({ children, pageContext, path }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <AuthProvider path={path}>
        <AppBar position="fixed" enableColorOnDark>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginRight: 0,
              minWidth: '100%',
            }}
          >
            <Link to="/">
              <Typography variant="h6">
                <FormattedMessage id="app.title">
                  {(title) => {
                    return `${title} - ${pageContext?.frontmatter?.title || ''}`
                  }}
                </FormattedMessage>
              </Typography>
            </Link>
            <LogoutButton />
          </Toolbar>
        </AppBar>
        {path !== '/login/' && <Navigation path={path} />}
        <Container>
          <Toolbar />
          {children}
        </Container>
      </AuthProvider>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  path: PropTypes.string.isRequired,
}

export default Layout
