import Cookies from 'js-cookie'
import apiClient from './basicapi'
import { navigate } from 'gatsby'

function getContactRequests() {
  return apiClient
    .get('contactservice/admin/contacts/form')
    .then((res) => res.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        Cookies.remove('jwt')
        navigate('/login/')
      } else {
        console.log(error)
      }
    })
}

function getAccesabilityContactRequests() {
  return apiClient
    .get('contactservice/admin/contacts/accessibility')
    .then((res) => res.data)
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        Cookies.remove('jwt')
        navigate('/login/')
      } else {
        console.log(error)
      }
    })
}

function getAccessabilityContactImage(id) {
  return apiClient.get(
    `contactservice/admin/contacts/accessibility/image/${id}`,
    {
      responseType: 'blob',
    }
  )
}

function deleteContactRequest(id) {
  return apiClient.delete(`contactservice/admin/contacts/form/${id}`)
}

function deleteAccessabilityContactRequest(id) {
  return apiClient.delete(`contactservice/admin/contacts/accessibility/${id}`)
}

function getNotificationRecipients() {
  return apiClient
    .get(`contactservice/admin/contact-recipients`)
    .then((res) => res.data)
}

function postNotificationRecipients(recipient) {
  return apiClient.post(`contactservice/admin/contact-recipients`, recipient)
}

function putNotificationRecipients(id, recipient) {
  return apiClient.put(
    `contactservice/admin/contact-recipients/${id}`,
    recipient
  )
}

function deleteNotificationRecipients(id) {
  return apiClient.delete(`contactservice/admin/contact-recipients/${id}`)
}

export {
  getContactRequests,
  getAccesabilityContactRequests,
  getAccessabilityContactImage,
  deleteContactRequest,
  deleteAccessabilityContactRequest,
  getNotificationRecipients,
  postNotificationRecipients,
  putNotificationRecipients,
  deleteNotificationRecipients,
}
