import React, { useEffect, useState, createContext } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { navigate } from 'gatsby'

import Loading from '@components/loading'
import { verifyUserToken, loggingIn } from '@services/authservice'
import { setAuthHeader } from '@services/basicapi'

import useSnackbar from '@hooks/useSnackbar'
import { useIntl } from 'react-intl'
const AuthContext = createContext()

// const ErrorMessageMap = {
//   'auth/user-not-found': 'Diese Mail Adresse hat hier keinen Account :(',
// }

export function AuthProvider({ children, path }) {
  const { toggleSnackbar } = useSnackbar()
  const [tokenVeryfied, setTokenVeryfied] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    verifyUserToken()
      .then((token) => {
        if (!!token) {
          setAuthHeader(token)
          setTokenVeryfied(true)
          if (path.includes('/login/')) navigate('/')
        }
      })
      .catch((e) => {
        console.error(e)
        navigate('/login/')
        if (e === 'Invalid or expired token') {
          toggleSnackbar(
            `${intl.formatMessage({ id: 'error.invalid' })}`,
            'error'
          )
        } else {
          toggleSnackbar(
            `Bitte wenden Sie sich an einen Administrator - ${e}`,
            'error'
          )
        }
      })
  }, [path])

  async function LoggingIn(name, password) {
    loggingIn(name, password)
      .then(() => navigate('/'))
      .catch((e) => {
        console.error(e)
        if (!!e.response && e.response.status === 401) {
          toggleSnackbar(
            `${intl.formatMessage({ id: 'error.login' })}`,
            'error'
          )
        } else {
          toggleSnackbar(
            `Bitte wenden Sie sich an einen Administrator - ${e}`,
            'error'
          )
        }
      })
  }

  function LoggingOut() {
    Cookies.remove('jwt')
    window.location.reload()
  }

  function switchLoading() {
    return typeof window === 'undefined' ||
      (!tokenVeryfied && !path.includes('/login/')) ? (
      <Loading />
    ) : (
      children
    )
  }
  return (
    <AuthContext.Provider value={{ LoggingIn, LoggingOut }}>
      {switchLoading()}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string.isRequired,
}

export default AuthContext
