/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React from 'react'
import * as ReactIntl from 'react-intl'
import Layout from './../src/components/layout/index'

import { messages } from './../src/translations/index'
import { defaultLang } from './../src/translations/index'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

const { SnackbarProvider } = require('../src/providers/SnackbarProvider')
const { DialogProvider } = require('../src/providers/Dialog/DialogProvider')

const createTheme = require('../config/theme/index')
const muitheme = createTheme()

export function wrapPage({ element, props }) {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <ThemeProvider theme={muitheme}>
        <SnackbarProvider>
          <DialogProvider>
            <CssBaseline />
            <Layout {...props}>{element}</Layout>
          </DialogProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ReactIntl.IntlProvider>
  )
}
