import Cookies from 'js-cookie'
import apiClient, { setAuthHeader } from './basicapi'

function verifyUserToken() {
  return new Promise((resolve, reject) => {
    const token = Cookies.get('jwt')
    if (token === undefined || token === '') {
      reject('Invalid or expired token')
    } else {
      resolve(token)
    }
  })
}

async function loggingIn(name, pass) {
  const response = await apiClient.post('contactservice/admin/login', {
    username: name,
    password: pass,
  })
  const token = response.data.jwt
  setAuthHeader(token)
  Cookies.set('jwt', token, { expires: 7 })
  return response
}

export { verifyUserToken, loggingIn }
