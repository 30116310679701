import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useSnackbar from '@hooks/useSnackbar'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Grid,
} from '@mui/material'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { postNotificationRecipients as postShoppingCardNotificationRecipients } from '@services/shoppingcardservice'
import { postNotificationRecipients as postContactNotificationRecipients } from '@services/contactrequestservice'

export default function AddRecipientDialog({ setOpen, variant, onOk }) {
  const intl = useIntl()
  const { toggleSnackbar } = useSnackbar()

  const initialValues = {
    name: '',
    email: '',
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(
      intl.formatMessage({ id: 'form.validation.required' })
    ),
    email: Yup.string()
      .email()
      .required(intl.formatMessage({ id: 'form.validation.required' })),
  })

  function onSubmitClick(values, actions) {
    if (variant === 'shoppingcard') {
      postShoppingCardNotificationRecipients(values)
        .then(() => {
          toggleSnackbar(
            intl.formatMessage({ id: 'dialog.success' }),
            'success'
          )
          actions.setSubmitting(false)
          if (onOk) onOk()
          setOpen(false)
        })
        .catch((e) => {
          console.log(e)
          toggleSnackbar(intl.formatMessage({ id: 'dialog.error' }), 'error')
        })
    } else {
      postContactNotificationRecipients(values)
        .then(() => {
          toggleSnackbar(
            intl.formatMessage({ id: 'dialog.success' }),
            'success'
          )
          actions.setSubmitting(false)
          if (onOk) onOk()
          setOpen(false)
        })
        .catch((e) => {
          console.log(e)
          toggleSnackbar(intl.formatMessage({ id: 'dialog.error' }), 'error')
        })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitClick}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
      }) => (
        <Form>
          <DialogTitle>
            <FormattedMessage id="dialog.addrecipient.title" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="dialog.addrecipient.copy" />
            </DialogContentText>
            <Grid container spacing={4} direction="column">
              <Grid item>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={!!touched.name && !!errors.name && errors.name}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="email"
                  name="email"
                  label="E-Mail Adresse"
                  fullWidth
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={!!touched.email && !!errors.email && errors.email}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} disabled={isSubmitting}>
              <FormattedMessage id="button.abort" />
            </Button>
            <Button type="submit" color="primary" disabled={isSubmitting}>
              <FormattedMessage id="button.ok" />
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}
