import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.GATSBY_CONTACTSERVICE_URL}/api/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 15000,
})

function setAuthHeader(token) {
  if (!token) return
  apiClient.defaults.headers.common.authorization = `Bearer ${token}`
}

export default apiClient
export { setAuthHeader }
